<template>
  <div class="home mingfang_home">
    <headert></headert>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="top-banner">
          <img src="../../assets/image/banner/Park.png" alt="">
        </div>
      </el-col>
    </el-row>
    <navMenu :activeIndex='menuActiveIndex' :menu='menu' :breadcrumb='breadcrumb' />
    <el-row class=" " type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="padding-tb70" >
          <div class="WJgroup-titleBox flex">
            <p class="WJgroup-title">{{$t('park.title')}}</p>
          </div>
        </div>
        <div class="flex align-start park_top_Box">
          <div class="parkLeftBox">
            <div>
              <router-link tag="a" to="/EntertainmentIndustrialPark" class="noactive ">
                <span class="font">{{$t('park.href1')}}</span>
              </router-link>
            </div>
            <div>
              <router-link tag="a" to="/EntertainmentMingfang" class="noactive activeBox">
                <span class="font">{{$t('park.href2')}}</span>
              </router-link>
            </div>
          </div>
          <div class="parkRightBox flex flex_column justify_center">
            <img class="parkRimg" src="../../assets/image/park/parbuild.png" alt="">
<!--            十万方一站式4S店集群-->
            <h6 :style="{'letter-spacing':lang=='zh'?'6px':'0' }">{{$t('park.mfSlogan')}}</h6>
            <p :style="{'letter-spacing':lang=='zh'?'6px':'0' }">{{$t('park.mfSubSlogan')}}</p>
          </div>
        </div>
      </el-col>

    </el-row>


    <el-row class="" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <div class="characteristicBox">
          <ul class="flex align-start characteristic">
            <li>
<!--              得天独厚的环境-->
              <p class="flex justify_center">{{$t('park.mftarget1')}}</p>
            </li>
            <li class="characteristicli2">
              <p class="flex justify_center">{{$t('park.mftarget2')}}</p>
            </li>
            <li class="characteristicli3">
              <p class="flex justify_center">{{$t('park.mftarget3')}}</p>
            </li>
            <li class="characteristicli4">
              <p class="flex justify_center">{{$t('park.mftarget4')}}</p>
            </li>
          </ul>
        </div>
        <ul class="parkTarget flex justify_around">
          <li>
<!--           高标准、高起点的规划建设 -->
            <img src="../../assets/image/park/mingf5.png" alt="">
            <p>{{$t('park.mftarget5')}}</p>
          </li>
          <li>
            <img src="../../assets/image/park/mingf6.png" alt="">
            <p>{{$t('park.mftarget6')}}</p>
          </li>
          <li>
            <img src="../../assets/image/park/mingf7.png" alt="">
            <p>{{$t('park.mftarget7')}}</p>
          </li>
          <li>
            <img src="../../assets/image/park/mingf8.png" alt="">
            <p>{{$t('park.mftarget8')}}</p>
          </li>
        </ul>

      </el-col>
    </el-row>
    <el-row class="" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <h6 class="mingfangTiltle">{{$t('park.introduceTitle')}}</h6>
        <div class="IntroductionPark">
          <p>
            {{$t('park.mfIntroduce1')}}
          </p>
          <p>
            {{$t('park.mfIntroduce2')}}
          </p>
        </div>
      </el-col>
    </el-row>
    <el-row class="brandSettleIn" type="flex" justify="center" align='center'>
      <el-col :xs="23" :sm="23" :md="23" :lg="18" :xl="18">
        <h6 class="mingfangTiltle">{{$t('park.brand')}}</h6>
        <div class="brandlistBox">


        <vue-seamless-scroll :data="brandList" :class-option="optionLeft" class="seamless-warp2">
          <ul class="brandlist flex ">
            <li><img src="../../assets/image/park/mingf10.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf11.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf12.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf13.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf14.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf15.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf16.png" alt=""></li>
            <li><img src="../../assets/image/park/mingf17.png" alt=""></li>
            <!-- <li><img src="../../assets/image/park/pbrand12.png" alt=""></li> -->
          </ul>
        </vue-seamless-scroll>
        </div>

      </el-col>
    </el-row>


    <!-- 底部 -->
    <footerb></footerb>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
  export default {
    name: "Home",
    components: {
      vueSeamlessScroll
    },
    data() {
      return {
        swiperOptions: {
          autoplay: {
            delay: 1000,
            disableOnInteraction: true
          },

          loop: true,
          effect: "fade",
          fade: {
            slideShadows: true,
            shadow: true,
            shadowOffset: 100,
            shadowScale: 0.6
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          pagination: {
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true
            // progressbarOpposite: true,
          }
        },

        menuActiveIndex: '/EntertainmentIndustrialPark',
        menu: {
          name: '产业布局',
          path: '/Industry',
          list: [{
            name: this.$t('menu.Property'),
            path: '/IndustryHouse',
          }, {
            name: this.$t('menu.park'),
            path: '/EntertainmentIndustrialPark',
          }, {
            name: this.$t('menu.entertainment'),
            path: '/Entertainment',
          }, {
            name: this.$t('menu.Commerce'),
            path: '/IndustryCommerce',
          }]
        },
        breadcrumb: [{
          name: this.$t('menu.layout'),
          path: '/IndustryHouse',
        }, {
          name: this.$t('menu.park'),
          path: '/EntertainmentIndustrialPark',
        }, {
          name: this.$t('park.href2'),
          path: '/EntertainmentMingfang',
        }],
        brandList: [{
          img: require('../../assets/image/park/mingf10.png')
        },
          {
            img: require('../../assets/image/park/mingf11.png')
          }, {
            img: require('../../assets/image/park/mingf12.png')
          },
          {
            img: require('../../assets/image/park/mingf13.png')
          },
          {
            img: require('../../assets/image/park/mingf14.png')
          }, {
            img: require('../../assets/image/park/mingf15.png')
          }, {
            img: require('../../assets/image/park/mingf16.png')
          }, {
            img: require('../../assets/image/park/mingf17.png')
          },
        ],
      };
    },
    computed: {
      lang(){
        return window.localStorage.getItem('language')
      },
      optionLeft() {
        return {
          step: 0.8,
          direction: 2, // 0向下 1向上 2向左 3向右
          limitMoveNum: this.brandList.length, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true,
          openTouch: false,

        };
      },
    },
    created() {},
    mounted() {
      new this.$wow.WOW().init()
    },
    methods: {
      onSwiper(swiper) {
        console.log(swiper);
      },
      onSlideChange() {
        console.log("slide change");
      }
    }
  };
</script>
<style lang="less" scoped>
  @keyframes rotatex {
    0% {
      transform: rotateX(0deg);
    }

    50% {
      transform: rotateX(180deg);
    }

    100% {
      transform: rotateX(180deg);
    }
  }

  .mingfangTiltle {
    text-align: center;
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 44px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #1F2D39;
    position: relative;
  }

  .mingfangTiltle::before {
    content: '';
    position: absolute;
    width: 32px;

    height: 3px;
    left: 50%;
    margin-left: -16px;
    bottom: -10px;
    background: #1F2D39;
  }

  .noactive {
    display: inline-block;
    width: 100%;
    height: 116px;
    background: linear-gradient(90deg, #ADAFB2 0%, #8F9294 100%);
    // font-family: 'OPPOSans-M';
    font-style: normal;
    font-weight: 400;
    font-size: 29px;
    line-height: 36px;
    margin-bottom: 1px;
    padding-left: 50px;
    box-sizing: border-box;
    color: #FFFFFF;
    display:flex;
    align-items: center;

  }

  .activeBox {
    height: 162px;
    background: linear-gradient(90deg, #E3CAA6 0%, #B5956F 100%);
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 28px;
      right: 0;
      width: 0;
      height: 0;
      border-right: 15px solid #fff;
      border-bottom: 15px solid transparent;
      border-top: 15px solid transparent;
      border-left: 15px solid transparent;
    }
  }







  .parkLeftBox {
    width: 360px;

  }

  .parkRightBox {
    flex: 1;
    position: relative;
    text-align: center;
    min-height: 280px;
    //padding-top: 100px;
  }

  .parkRightBox h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 52px;
    letter-spacing: 6px;
    color: #333333;
  }

  .parkRightBox p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 58px;
    letter-spacing: 6px;
    color: #A4825F;
    margin-top: 36px;
  }

  .parkRimg {
    position: absolute;
    bottom: 0;
    right: -22%;
  }

  .parkIntroduceBox {
    padding-top: 70px;
    padding-bottom: 92px;
  }

  .parkIntroduce {
    width: 40%;
  }

  .parkIntroduce h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 36rpx;
    color: #333333;
  }

  .parkIntroduce p {
    margin-top: 12px;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 40px;
  }

  .parkIntroduceimg {
    margin-left: 2%;
    flex: 1;
    padding-top: 31.5%;
    position: relative;
    overflow: hidden;
  }

  .parkIntroduceimg img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .parkTitle {
    text-align: center;
  }

  .parkTitle h5 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 46px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #1F2D39;
  }

  .parkTitle h6 {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 35px;
    color: #1F2D39;
  }

  .parkTarget {
    padding-top: 40px;
    padding-bottom: 116px;
  }

  .parkTarget li {
    text-align: center;
  }

  .parkTarget img {
    width: 76px;
    height: 76px;
  }

  .parkTarget p {
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-top: 23px;

  }


  .characteristicBox {
    padding-top: 180px;
    padding-bottom: 75px;
  }



  .characteristic {
    flex-wrap: wrap;
  }

  .characteristic li {
    width: 50%;
    background: url('../../assets/image/park/mingf1.png') no-repeat;
    background-size: cover;
    width: 50%;
    padding-top: 22%;
    position: relative;
  }

  .characteristic .characteristicli2 {
    background: url('../../assets/image/park/mingf2.png') no-repeat;
    background-size: cover;
  }

  .characteristic .characteristicli3 {
    background: url('../../assets/image/park/mingf3.png') no-repeat;
    background-size: cover;
  }

  .characteristic .characteristicli4 {
    background: url('../../assets/image/park/mingf4.png') no-repeat;
    background-size: cover;
  }

  .characteristic li p {

    position: absolute;
    top: 0;
    bottom: 0;
    left: 15%;
    right: 0;
    width: 70%;
    // font-family: 'OPPOSans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 60px;
    text-align: center;
    color: #FFFFFF;
    letter-spacing: 4.5px;
  }

  .brandSettleIn {

    padding-top: 120px;
  }


  .brandlistBox {
    overflow: hidden;
    margin-top: 140px;
    height: 120px;
    margin-bottom: 110px;
  }
  .brandlist {
    //margin-top: 100px;
    //margin-bottom: 110px;
    flex-wrap: nowrap;
    height: 120px;
  }

  .brandlist li {
    flex-shrink: 0;
    width: 269px;
    height: 120px;
    //width: 17%;
    //margin-bottom: 66px;
    //margin-right: 10.6%;
    margin-right: 30px;

  }
  .brandlist li:nth-child(4n){
    margin-right: 0;
  }
   .brandlist li img{
     width: 100%;
   }



  .IntroductionPark{
      margin-top: 68px;
      // font-family: 'OPPOSans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      color: #000000;
  }
  .IntroductionPark p{
    margin-bottom: 20px;
  }
  @media screen and (min-width:320px) and (max-width:767px) {
    .mingfang_home .park_top_Box {
      display: block;
    }

    .parkLeftBox {
      display: flex;
      width: 100%;

      div {
        width: 50%;
      }

      .activeBox {
        height: 116px;
      }
    }
    .parkRightBox {
      width: 100%;
      .parkRimg {
        width: 100%;
        right: 0;
      }
    }

    .characteristicBox{
      padding-top: 80px;
    }
    .characteristic li p{
      font-size: 39px;
    }
    .parkTarget{
      flex-wrap: wrap;
      li{
        width: 50%;
        margin-bottom: 30px;
        img{
          width: 99px;
          height: 99px;
        }
        p{
          font-size: 34px;
        }
      }
    }
    .mingfangTiltle{
      font-size: 39px;
    }
    .IntroductionPark p{
      font-size: 34px;
      line-height: 50px;

    }

  }
</style>
